<template>
  <aside class="sidebar">
    <b-modal
      v-model="addRedditModalShow"
      class="modal add-reddit-modal content-centered"
      id="add-reddit-modal"
      hide-header
      hide-header-close
      hide-footer
      hide-backdrop
    >
      <div class="modal__backdrop" @click="addRedditModalShow = !addRedditModalShow"></div>
      <div class="modal__wrap content-centered">
        <form class="modal__content">
          <div class="modal__close" @click="addRedditModalShow = !addRedditModalShow">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L21 21M21 1L1 21" stroke="#747185" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          </div>
          <h4 class="modal__title">Добавить Reddit</h4>
          <label class="label-wrap login__form-control">
            <input v-model="addSubredditForm.name" type="text" class="input input--w100" placeholder="Название" />
            <FormListErrorPrinter :errorList="addSubredditFormErrors.name" />
          </label>
          <button class="button login__submit" type="button" @click="addReddit">Добавить</button>
        </form>
      </div>
    </b-modal>

    <b-modal
      v-model="deleteRedditModalShow"
      class="modal delete-reddit-modal"
      id="delete-reddit-modal"
      hide-header
      hide-header-close
      hide-footer
      hide-backdrop
    >
      <div class="modal__backdrop" @click="deleteRedditModalShow = !deleteRedditModalShow"></div>
      <div class="modal__wrap content-centered">
        <form class="modal__content">
          <div class="modal__close" @click="deleteRedditModalShow = !deleteRedditModalShow">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L21 21M21 1L1 21" stroke="#747185" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          </div>
          <h4 class="modal__title">Удалить Reddit</h4>
          <p class="delete-reddit-modal__text">
            Вы действительно хотите удалить <span>{{ subredditName(this.deleteSubredditId) }}</span
            >?
          </p>
          <div class="modal__buttons">
            <button class="button button--cancel" @click="deleteRedditModalShow = !deleteRedditModalShow">
              Отменить
            </button>
            <button @click="deleteSubreddit" class="button login__submit" type="button">Удалить</button>
          </div>
        </form>
      </div>
    </b-modal>

    <div @click="logoClick" class="cursor-pointer sidebar__logo content-centered">
      <img src="/img/logo.png" alt="Big Boss Reddit" />
    </div>
    <div class="sidebar__bars" @click="sidebarShow = !sidebarShow" :class="{ active: sidebarShow }">
      <span class="sidebar__bar"></span>
      <span class="sidebar__bar"></span>
      <span class="sidebar__bar"></span>
    </div>
    <div class="sidebar__content" :class="{ active: sidebarShow }">
      <nav class="nav sidebar__nav">
        <ul class="nav__list">
          <li
            class="nav__item"
            :class="{ active: subreddit.id == $route.params.subredditId }"
            v-for="subreddit in getSubreddits"
            :key="subreddit.id"
            @click="$router.push({ name: 'subreddit', params: { subredditId: subreddit.id } })"
          >
            <a @click.prevent="" href="#" class="nav__item-wrap">
              <span
                v-if="getUser.is_admin"
                class="nav__item-delete"
                data-modal="#delete-reddit-modal"
                @click.prevent.stop="showDeleteModal(subreddit.id)"
              >
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L16 16M16 1L1 16" stroke="#747185" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </span>
              <span class="nav__item-bar"></span>
              <img src="/img/nav-icon.svg" alt="" />
              <span>{{ subreddit.name }}</span>
            </a>
          </li>
        </ul>
      </nav>
      <div class="view-settings sidebar__view-settings">
        <b-button
          v-if="getUser.is_admin"
          @click="addRedditModalShow = !addRedditModalShow"
          class="clickable-item clickable-item--w100 active nav__item-wrap"
          data-modal="#add-reddit-modal"
        >
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.5 1V16M16 8.5L1 8.5"
              stroke="#EAE6FF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Добавить Reddit</span>
        </b-button>
        <div class="view-settings__type">
          <div class="view-settings__title">Отображение тикетов:</div>
          <ul class="view-settings__items">
            <li
              @click="$emit('expanded', false)"
              class="clickable-item view-setting__item view-setting__item--100"
              :class="{ active: !expanded }"
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.0001 0.999999L11.0001 7M11.0001 7L11.0001 1M11.0001 7L17.0001 7"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.00006 16.9997L7.00006 10.9997M7.00006 10.9997L7.00006 16.9997M7.00006 10.9997L1.00006 10.9997"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>В свернутом виде</span>
            </li>
            <li
              @click="$emit('expanded', true)"
              :class="{ active: expanded }"
              class="clickable-item view-setting__item view-setting__item--100"
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.0001 7.00015L17.0001 1.00015M17.0001 1.00015V7.00015M17.0001 1.00015L11.0001 1.00015"
                  stroke="#B6B6B6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.00024 11.0002L1.00024 17.0002M1.00024 17.0002L1.00024 11.0002M1.00024 17.0002L7.00024 17.0002"
                  stroke="#B6B6B6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>В развернутом виде</span>
            </li>
          </ul>
        </div>
        <div class="view-settings__type">
          <div class="view-settings__title">Кол-во тикетов:</div>
          <ul class="view-settings__items">
            <li
              class="clickable-item view-setting__item"
              @click="$emit('perPage', 25)"
              :class="{ active: perPage === 25 }"
            >
              25
            </li>
            <li
              class="clickable-item view-setting__item"
              @click="$emit('perPage', 50)"
              :class="{ active: perPage === 50 }"
            >
              50
            </li>
            <li
              class="clickable-item view-setting__item"
              @click="$emit('perPage', 100)"
              :class="{ active: perPage === 100 }"
            >
              100
            </li>
          </ul>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormListErrorPrinter from "./FormErrorListPrinter.vue";

const addSubredditDefaultForm = {
  name: null,
};

export default {
  name: "Sidebar",
  components: {
    FormListErrorPrinter,
  },
  props: {
    perPage: {
      type: Number,
      default: 25,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sidebarShow: false,
      addRedditModalShow: false,
      deleteRedditModalShow: false,
      subreddits: [],
      addSubredditForm: { ...addSubredditDefaultForm },
      addSubredditFormErrors: { ...addSubredditDefaultForm },
      deleteSubredditId: null,
    };
  },
  computed: {
    ...mapGetters("RedditStore", ["getSubreddits", "getUser"]),
  },
  methods: {
    subredditName(subredditId) {
      let reddit = this.getSubreddits.find((i) => i.id == subredditId);
      if (!reddit) {
        return "";
      }
      return reddit.name;
    },
    async addReddit() {
      let loader = this.$loading.show();
      this.addSubredditFormErrors = { ...this.addSubredditDefaultForm };
      try {
        await this.addSubredditAction(this.addSubredditForm);
        this.$toast.success("Успех");
        this.addRedditModalShow = false;
        await this.updateSubredditList();
        this.addSubredditForm.name = null;
        // await this.$router.push({ name: "index" });
      } catch (e) {
        this.addSubredditFormErrors = e.response.data.errors;
        this.$toast.error("Ошибка добавления сабреддита");
      } finally {
        loader.hide();
      }
    },
    logoClick() {
      if (!["index", "subreddit"].includes(this.$route.name)) {
        this.$router.push({ name: "index" });
      }
    },
    showDeleteModal(subredditId) {
      this.deleteRedditModalShow = true;
      this.deleteSubredditId = subredditId;
    },
    async deleteSubreddit() {
      let loader = this.$loading.show();
      try {
        await this.deleteSubredditAction(this.deleteSubredditId);
        this.$toast.success("Успех");
        this.deleteRedditModalShow = false;
        await this.updateSubredditList();
        // await this.$router.push({ name: "index" });
      } catch (e) {
        this.$toast.error("Ошибка удаления сабреддита");
      } finally {
        loader.hide();
      }
    },
    async updateSubredditList() {
      await this.fetchSubredditsAction();
    },
    ...mapActions("RedditStore", {
      addSubredditAction: "ADD_SUBREDDIT",
      deleteSubredditAction: "DELETE_SUBREDDIT",
      fetchSubredditsAction: "FETCH_SUBREDDITS",
    }),
  },
  async mounted() {
    await this.updateSubredditList();
  },
};
</script>
